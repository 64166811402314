import React from 'react';
 import './MyProfile.css';

const Profile = () => {
    return (
        <div className="resume-container">
            <header className="resume-header">
                <h1>Abhinav Birendra</h1>
                <h2>Java Backend Developer</h2>
                <div className="contact-info">
                    <p>Pune | +91-8409132328 | <a href="mailto:abhinav1141@gmail.com">abhinav1141@gmail.com</a></p>
                    <p><a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">LinkedIn</a> | <a href="https://leetcode.com/yourprofile" target="_blank" rel="noopener noreferrer">LeetCode</a></p>
                </div>
            </header>

            <section className="skills-section">
                <h3>Skills</h3>
                <div className="skills-grid">
                    <div className="skill-category">
                        <h4>Programming Languages</h4>
                        <ul>
                            <li>Java</li>
                            <li>Javascript</li>
                            <li>C++</li>
                            <li>SQL</li>
                        </ul>
                    </div>
                    <div className="skill-category">
                        <h4>Libraries and Frameworks</h4>
                        <ul>
                            <li>Spring Boot</li>
                            <li>React.js</li>
                            <li>Redux</li>
                            <li>Redis</li>
                        </ul>
                    </div>
                    <div className="skill-category">
                        <h4>Tools</h4>
                        <ul>
                            <li>Git</li>
                            <li>Docker</li>
                            <li>Visual Studios</li>
                            <li>Intellij</li>
                            <li>Maven</li>
                            <li>Jira</li>
                            <li>AWS</li>
                            <li>Kafka</li>
                        </ul>
                    </div>
                    <div className="skill-category">
                        <h4>Other Skills</h4>
                        <ul>
                            <li>DataStructures & Algorithms</li>
                            <li>Microservices</li>
                            <li>System Design</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="experience-section">
                <h3>Experience</h3>
                <div className="job">
                    <div className="job-header">
                        <h4>Software Specialist</h4>
                        <p>PTC, Pune | Jun 2022 - Present</p>
                    </div>
                    <ul>
                        <li>Led efforts to incorporate Java and Spring Boot into Windchill's existing architecture, enhancing the BOM module and resulting in 15% time savings for engineers.</li>
                        <li>Integrated Windchill's ECR feature with existing systems using RESTful API endpoints, decreasing product development cycle time by 25%.</li>
                        <li>Enforced Agile methodologies with Jira and Confluence to develop custom Windchill plugins, boosting overall productivity by 30%.</li>
                    </ul>
                    <p><strong>Tech Stack:</strong> Java, Spring Boot, Hibernate, MySQL, Junit, Git, AWS</p>
                </div>
                <div className="job">
                    <div className="job-header">
                        <h4>Software Developer</h4>
                        <p>Accenture, Pune | Jan 2021 - Jun 2022</p>
                    </div>
                    <ul>
                        <li>Led development of web applications for H&M's project, achieving a 30% increase in user engagement.</li>
                        <li>Directed design and deployment of Java-based RESTful APIs, optimizing user experience and reducing API response time by 25%.</li>
                    </ul>
                    <p><strong>Tech Stack:</strong> Java, JavaScript, React.js, MongoDB</p>
                </div>
            </section>

            <section className="projects-section">
                <h3>Projects</h3>
                <div className="project">
                    <h4>DAR AL MAREFA</h4>
                    <a href="https://www.daralmarefa.ae/" target="_blank" rel="noopener noreferrer">www.daralmarefa.ae</a>
                    <ul>
                        <li>Engineered website for Dubai-based school, boosting user engagement by 25% through responsive design and React.js.</li>
                        <li>Implemented SEO best practices, increasing organic Google search indexing by 30% and improving online visibility by 25%.</li>
                        <li>Spearheaded database optimization techniques, decreasing query response time by 20%.</li>
                    </ul>
                    <p><strong>Tech Stack:</strong> Java, JavaScript, React.js, Redux, CSS, HTML, MongoDB, AWS</p>
                </div>
                <div className="project">
                    <h4>BookMyShow</h4>
                    <a href="https://github.com/abhinav11411141/BookMyShow.git" target="_blank" rel="noopener noreferrer">GitHub Repository</a>
                    <ul>
                        <li>Developed BookMyShow clone with end-to-end flow for booking shows.</li>
                        <li>Implemented features following S.O.L.I.D principles and design patterns.</li>
                        <li>Efficient transaction handling at Isolation level ensuring no duplicate bookings.</li>
                    </ul>
                    <p><strong>Tech Stack:</strong> Java, OOPs, Microservices, JPA, Spring JDBC</p>
                </div>
            </section>

            <section className="achievements-section">
                <h3>Achievements</h3>
                <ul>
                    <li>Solved 500+ problems on LeetCode</li>
                    <li>Ranked 106/28991 in Scaler CodeX 2.0</li>
                    <li>PTC Innovation Award at PTC Software</li>
                    <li>Participated in Green X Game Jam organised by Intel</li>
                </ul>
            </section>

            <section className="education-section">
                <h3>Education</h3>
                <div className="education">
                    <h4>Scaler</h4>
                    <p>Specialized in Software Development & Problem Solving | 2024</p>
                    <p>Coursework: DSA, Low level design, High level design, Java Backend development</p>
                </div>
                <div className="education">
                    <h4>Pune University</h4>
                    <p>BE/B.Tech/BS in Computer Science | 2020</p>
                </div>
            </section>
        </div>
    );
};

export default Profile;
