import './App.css';
import Profile from "./Profile";

function App() {
  return (
    <div className="App">
      <Profile/>
    </div>
  );
}

export default App;
